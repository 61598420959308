export const state = {
  lang: 'zh-CN',
  langArr: '',
  token: '',
  companyInfo: {},
  platformConfig: {},
  adminLink: '',
  platform: 'web',
  isInformation: false,
  changePassword: false,
  passwordExpire: false,
  userInfo: {},
  msgCount: 0,
  isTeacher: 4,
  isExternal: '', // 外部账号
};
