<template>
  <div class="foot" v-if="!ISHTSY">
    <div class="content">
      <div class="copy">
        <span
          v-if="studyCopyright"
          style="padding: 0 5px"
          v-html="studyCopyright"
        ></span>
        <a
          :href="`https://beian.miit.gov.cn${
            newWindow == 1 ? '?ddtab=true' : ''
          }`"
          :target="newWindow == 1 ? '_blank' : '_self'"
          v-if="icpNo"
        >
          {{ icpNo }}
        </a>
        <a
          v-if="ncNo"
          :target="newWindow == 1 ? '_blank' : '_self'"
          :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
            newWindow == 1 ? '&ddtab=true' : ''
          }`"
        >
          公网安备 {{ ncNo }}号
        </a>
      </div>
    </div>
  </div>
  <div class="footer" v-else>
    <div class="birdWrapper">
      <div id="birdPositionLeft"></div>
      <img src="../../assets/image/bottom_blue.png?v=1" id="bird" />
      <div id="birdPositionRight"></div>
    </div>

    <div class="content">
      <div class="copyright" style="width: 100%">
        <table
          cellspacing="0"
          cellpadding="0"
          align="center"
          border="0"
          width="100%"
        >
          <tbody>
            <tr>
              <td class="sv_black12_24" align="center">
                版权所有：中国航天科工集团第三研究院第三总体设计部
                系统密级：机密<br />
                技术支持热线：34524&nbsp;&nbsp;值班手机：13693065015
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { appendJs } from "@/utils/tools.js";

export default {
  name: "Foot",
  setup() {
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const store = useStore();
    const state = reactive({
      logo: "",
      studyCopyright: "",
      icpNo: "",
      ncNo: "",
    });
    watch(
      () => store.getters.platformConfig,
      (val) => {
        state.logo = val.logo1;
        state.studyCopyright = val.studyCopyright;
        state.icpNo = val.icpNo;
        state.ncNo = val.ncNo;
        appendJs("Track", val.studyTrack);
      },
      { immediate: true, deep: true }
    );

    return {
      ISHTSY,
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.foot {
  background-color: #2f2e2e;
  .content {
    .mixinWrap();
    .top {
      .mixinFlex(space-between);
      padding: 60px 0 50px;
      .logo {
        .mixinImgWrap(132px; 32px);
        span {
          font-size: 18px;
          letter-spacing: 4px;
          .mixinFlex(center; center);
          color: #fff;
          text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.1);
        }
      }
      .right {
        width: 656px;
        color: #fff;
        .mixinFlex(space-between);
        h6 {
          color: #fff;
          font-size: 14px;
          margin: 0;
        }
        .links {
          a {
            display: block;
            line-height: 1.5;
            margin-top: 20px;
            color: #fff;
            font-size: 12px;
          }
        }
        .contact {
          .item {
            .mixinFlex();
            p {
              font-size: 12px;
              line-height: 1.5;
              margin: 20px 0 0 0;
            }
          }
        }
        .app {
          .qr {
            margin-top: 20px;
            .mixinImgWrap(80px; 80px);
          }
        }
      }
    }
    .copy {
      border-top: 1px solid rgba(255, 255, 255, 0.11);
      line-height: 90px;
      font-size: 12px;
      color: #808080;
      text-align: center;
      ::v-deep(a) {
        color: #808080 !important;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.footer {
  background-color: #e6e6e6;
  overflow: hidden;
  width: 100%;
  font: normal 12px/2em Microsoft YaHei;
  color: #7b7b7b;
  padding-bottom: 10px;
}
.footer a:link,
.footer a:visited {
  text-decoration: none;
  font: normal 12px/2em Microsoft YaHei;
  color: #7b7b7b;
}
.footer .content {
  width: 1100px;
  margin: 0 auto; /*padding:20px;*/
  overflow: auto;
}
.footer .link {
  width: 500px;
  padding: 0;
  margin: 0;
  float: left;
}
.footer .link dt {
  font: normal 18px/2.5em Microsoft YaHei;
  border-bottom: solid 1px #bfbfbf;
  margin-bottom: 10px;
  padding-left: 5px;
}
.footer .link dd {
  padding: 0;
  margin: 0;
  margin-left: 5px;
  float: left;
  width: 160px;
}
.footer .copyright {
  width: 600px;
  float: right; /*margin-top:50px;*/
}
.footer .copyright p {
  float: left;
  margin: 0;
  padding-left: 130px;
  min-height: 65px;
  margin-left: 30px;
  line-height: 2em;
  background: url("../../assets/image/bottom_blue.png") no-repeat;
}
.birdWrapper {
  position: relative;
  content: "";
  display: block;
  width: 100%;
  height: 20px;
}

#bird {
  width: 60px;
  position: absolute;
  left: 14%;
  top: 0;
  z-index: 1000;
}

#birdPositionLeft {
  width: 14%;
  height: 6.6px;
  background: #1b2e5b;
  position: absolute;
  left: 0;
  top: 0;
}

#birdPositionRight {
  width: 100%;
  height: 6.6px;
  background: #1b2e5b;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
