import { createRouter, createWebHistory } from "vue-router";
import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';
import ls from '@/utils/local-storage';
import layout from '@/layout';
import views from './views';
import teacherLayout from '@/layout/teacher';
import teacher from './teacher';
import { ToLogin } from "@/utils/request";
import { onePageUrl, menusTitleSet } from "@/utils/business";
import { companyInfo } from "@/api/user";
import i18n from "../locale/i18n";


let LOGINPAGEROUTE = '/login';
if (process.env.VUE_APP_CLIENT == 'htsy') { // 三院
    LOGINPAGEROUTE = '/oprt_lgin'
}
let homePage = [
  {
      path: '/',
      name: 'index',
      redirect: '/',
      component: layout,
      children: views
  },
]
if (process.env.VUE_APP_CLIENT === 'portal') {
  homePage = [
    {
        path: '/',
        name: 'portal',
        meta: {
            title: 'Pub_Portal_Website',
            requireAuth: false,
            keepAlive: false
        },
        component: () => import('@/views/portal/index.vue')
    },
    {
        path: '/home',
        name: 'index',
        component: layout,
        children: views
    },
  ]
}
const routes = [
    ...homePage,
    {
        path: '/teacher',
        name: 'teacher',
        redirect: '/teacher/calendar',
        component: teacherLayout,
        children: teacher
    },
    {
        path: LOGINPAGEROUTE,
        name: 'login',
        meta: {
            title: 'CM_SignIn',
            requireAuth: false
        },
        component: () => import('@/views' + LOGINPAGEROUTE + '.vue')
    },
    {
        path: '/login/switch',
        name: 'login_switch',
        meta: {
            title: 'XB_PlatformSwitching',
            requireAuth: false
        },
        component: () => import('@/views/login/switch.vue')
    },
    {
        path: '/login/feishu',
        name: 'login_feishu',
        meta: {
            title: 'XB_LarkLogin',
            requireAuth: false
        },
        component: () => import('@/views/login/feishu.vue')
    },
    {
        path: '/login/ding',
        name: 'login_ding',
        meta: {
            title: 'XB_DingTalkLogin',
            requireAuth: false
        },
        component: () => import('@/views/login/ding.vue')
    },
    {
        path: '/login/dingSku',
        name: 'login_dingSku',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/login/dingSku.vue')
    },
    {
        path: '/login/oidc',
        name: 'login_oidc',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/login/oidc.vue')
    },
    {
        path: '/login/wxwork',
        name: 'login_wxwork',
        meta: {
            title: 'XB_WeComLogin',
            requireAuth: false
        },
        component: () => import('@/views/login/wxwork.vue')
    },
    {
        path: '/login/wxworkh5',
        name: 'login_wxworkh5',
        meta: {
            title: 'XB_WeComH5Login',
            requireAuth: false
        },
        component: () => import('@/views/login/wxworkh5.vue')
    },
    {
        path: '/login/partnersso',
        name: 'login_partnersso',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/login/partnersso.vue')
    },
    {
        path: '/login/partnerssoV2',
        name: 'login_partnerssoV2',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/login/partnerssoV2.vue')
    },
    {
        path: '/install/wxwork',
        name: 'install_wxwork',
        meta: {
            title: 'XB_WeComLogin',
            requireAuth: false
        },
        component: () => import('@/views/install/wxwork.vue')
    },
    {
        path: '/login/wx',
        name: 'login_wx',
        meta: {
            title: 'XB_WechatLogin',
            requireAuth: false
        },
        component: () => import('@/views/login/wx.vue')
    },
    {
        path: '/forgot',
        name: 'forgot',
        meta: {
            title: 'login.forgot',
            requireAuth: false
        },
        component: () => import('@/views/forgot.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: 'XB_PersonalRegistration',
            requireAuth: false
        },
        component: () => import('@/views/register/index.vue')
    },
    {
        path: '/register/company',
        name: 'register_company',
        meta: {
            title: 'login.enterprise_registration',
            requireAuth: false
        },
        component: () => import('@/views/register/company.vue')
    },
    {
        path: '/doc/detail',
        name: 'doc_detail',
        meta: {
            title: 'XB_KnowledgeDetail',
            requireAuth: true
        },
        component: () => import('@/views/doc/detail.vue')
    },
    {
        path: '/exam/paper',
        name: 'exam_paper',
        meta: {
            title: 'XB_ExamAnswers',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/paper.vue')
    },
    {
        path: '/map/detail',
        name: 'map_detail',
        meta: {
            title: 'XB_LearnMapDetails',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/map/detail.vue')
    },
    {
        path: '/live/room',
        name: 'live_room',
        meta: {
            title: 'XB_LiveBroadcastRoom',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/live/room.vue')
    },
    {
        path: '/live/playBack',
        name: 'live_playBack',
        meta: {
            title: 'live.view_replay',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/live/playBack.vue')
    },
    {
        path: '/mine/private',
        name: 'mine_private',
        meta: {
            title: 'XB_PrivacyPolicy',
            requireAuth: false,
        },
        component: () => import('@/views/mine/private.vue')
    },
    {
        path: '/mine/useragreement',
        name: 'mine_useragreement',
        meta: {
            title: 'XB_UserAgreement',
            requireAuth: false,
        },
        component: () => import('@/views/mine/useragreement.vue')
    },
    {
        path: '/error',
        name: 'error',
        meta: {
            title: 'XB_ErrorPage',
            requireAuth: false,
        },
        component: () => import('@/views/error.vue')
    },
    {
        path: '/login_error',
        name: 'login-error',
        meta: {
            title: 'XB_LoginPrompt',
            requireAuth: false
        },
        component: () => import('@/views/login_error.vue')
    },
    {
        path: '/sso/htsy/auth',
        name: 'htsy-auth',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/sso/htsy/auth.vue')
    },
    {
        path: '/sso/htsy/task_auth',
        name: 'htsy-task-auth',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/sso/htsy/task_auth.vue')
    },
    {
        path: '/sso/htsy/audit_auth',
        name: 'htsy-audit-auth',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/sso/htsy/audit_auth.vue')
    },
    {
        path: '/preview',
        name: 'preview',
        meta: {
            title: 'XB_Preview',
            requireAuth: true,
        },
        component: () => import('@/views/preview.vue')
    },
    {
        path: '/licence',
        name: 'licence',
        meta: {
            title: 'XB_Unauthorized',
            requireAuth: false,
        },
        component: () => import('@/views/licence.vue')
    },
    {
        path: '/exam/record',
        name: 'exam_record',
        meta: {
            title: 'XB_ExamRecord',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/record.vue')
    },
    {
        path: '/aiPractice/result',
        name: 'aiPractice_result',
        meta: {
            title: 'PracticeResults',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/aiPractice/result.vue')
    },
    {
        path: '/mine/down',
        name: 'mine_down',
        meta: {
            title: 'downloadCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/down.vue')
    },
    {
        path: '/traingo_chat_gpt',
        name: 'chat_gpt',
        meta: {
            title: 'chatGpt',
            requireAuth: false,
            keepAlive: false
        },
        component: () => import('@/views/chat/gpt.vue')
    },
    {
        path: '/sso/iam',
        name: 'sso-iam',
        meta: {
            title: 'Pub_Authorize',
            requireAuth: false
        },
        component: () => import('@/views/sso/iam.vue')
    },
    // 备用登录页
    {
        path: '/oprt_lgin',
        name: 'oprt_lgin',
        meta: {
            title: 'CM_SignIn',
            requireAuth: false
        },
        component: () => import('@/views/oprt_lgin.vue')
    },
    {
        path: '/noAuth/collect',
        name: 'noAuth_collect',
        meta: {
            title: 'informationCollection',
            requireAuth: false
        },
        component: () => import('@/views/noAuth/collect.vue')
    },
    {
        path: '/noAuth/enroll',
        name: 'noAuth_enroll',
        meta: {
            title: 'EnrollmentInfor',
            requireAuth: false
        },
        component: () => import('@/views/noAuth/enroll.vue')
    },
    {
        path: '/audit',
        name: 'auditCenter',
        meta: {
            title: 'AuditCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/auditCenter/index.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 }
    }
});

router.beforeEach((to, from, next) => {
    // 飞书消息
    if (to.href.indexOf('/view') > -1 && to.href.indexOf('from=') > -1) {
      window.location.href = to.href.replace(/\/view/, '');
      return
    }
    if (to.meta.requireAuth) {
        if (!ls.get(STORAGE_TOKEN_KEY)) {
            ToLogin(to.fullPath);
        } else {
            document.title = i18n.global.t(to.meta.title)
            menusTitleSet(to.path)
            // 单页版本模块跳转
            if (
                to.path == '/' ||
                (to.path.includes('/mine') && to.fullPath != '/mine/study/profile') ||
                to.path == '/course'
            ) {
                companyInfo().then(res => {
                    if (res.ret === 0) {
                        const mobileModule = res.data.mobileModule;
                        if ([1, 2, 3].includes(mobileModule)) {
                            const url = onePageUrl[mobileModule];
                            next(url);
                        } else {
                            next();
                        }
                    };
                });
            } else {
                next();
            }

        }
    } else {
        if (to.meta && to.meta.title) {
            document.title = i18n.global.t(to.meta.title)
        }
        next()
    }
});

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

export default router;
