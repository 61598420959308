<template>
  <a-config-provider :locale="locale">
    <template #renderEmpty>
      <a-empty
        :image="require('@/assets/image/no_data_3.png')"
        :image-style="{ height: '186px' }"
        style="padding: 60px 0"
      />
    </template>
    <router-view />
    <change-password v-if="getShowPassword" />
    <complete-information />
  </a-config-provider>
</template>

<script>
import { getCurrentInstance, onErrorCaptured, computed, nextTick } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import enUS from "ant-design-vue/es/locale/en_US";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import { upFavicon, getQueryVariable } from "@/utils/tools";
import changePassword from "@/components/changePassword.vue";
import completeInformation from "@/components/completeInformation.vue";
import { platformConfig, report } from "@/api/other";
import { OIDCGetAuthUrl, getAuthUrlByqd } from "@/api/user";
import { currentHost } from "@/utils/request.js";
import { STORAGE_TOKEN_KEY } from "@/store/mutation-type";
import { setLang, clearStorage, getCurrentPlatform } from "@/utils/tools";

export default {
  components: {
    changePassword,
    completeInformation,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const locale = computed(() => {
      let lang = store.getters.lang;
      if (lang == "zh-CN") {
        return zhCN;
      } else if (lang == "zh-TW") {
        return zhTW;
      } else if (lang == "en-US") {
        return enUS;
      }
    });
    const langNamesEnum = {
      zh_CN: "zh-CN",
      zh_TW: "zh-TW",
      en_US: "en-US",
    };
    let currentLang = ls.get("locale") || "zh-CN";
    store.dispatch("setLang", currentLang);
    proxy.$i18n.locale = currentLang;

    function hexToRgba(hex, opacity = 0.1) {
      return (
        "rgb(" +
        parseInt("0x" + hex.slice(1, 3)) +
        "," +
        parseInt("0x" + hex.slice(3, 5)) +
        "," +
        parseInt("0x" + hex.slice(5, 7)) +
        "," +
        opacity +
        ")"
      );
    }

    // 平台功能配置
    platformConfig({ site: currentHost, platform: getCurrentPlatform() }).then(
      (res) => {
        let sso = getQueryVariable("login");
        if (sso == "sso") {
          if (
            res.data &&
            res.data.useOIDC == 1 &&
            location.pathname.indexOf("/login/oidc") == -1 &&
            location.pathname.indexOf("/oprt_lgin") == -1
          ) {
            clearStorage();
            OIDCGetAuthUrl({
              mode: 1,
              customerId: res.data.CustomerID,
              authMode: res.data.customerCode == "hi-p" ? 1 : 0, // 赫比
              state:
                location.pathname.indexOf("/login") == -1
                  ? location.pathname + location.search
                  : "/",
            }).then((OIDCRes) => {
              window.location.href = OIDCRes.data;
            });
          }
        }
        // 青岛水务
        if (
          !ls.get(STORAGE_TOKEN_KEY) &&
          res.data &&
          res.data.useIAM == 1 &&
          location.pathname.indexOf("/sso/iam") == -1 &&
          location.pathname.indexOf("/oprt_lgin") == -1
        ) {
          clearStorage();
          const param = {
            customerId: res.data.CustomerID,
            url: location.protocol + "//" + location.host + "/sso/iam",
            state:
              location.pathname.indexOf("/login") == -1
                ? location.pathname
                : "/",
            mode: 1, // 1=pc 2=mobile
          };
          getAuthUrlByqd(param).then((res) => {
            window.location.href = res;
          });
        }
        if (res.data.logo2) upFavicon(res.data.logo2);
        if (res.data.theme) {
          document.body.style.setProperty("--theme", res.data.theme);
          document.body.style.setProperty(
            "--light-theme",
            hexToRgba(res.data.theme)
          );
        }
        if (!ls.get(STORAGE_TOKEN_KEY)) {
          nextTick(() => {
            setLang(res.data.languages);
          });
        }
        store.dispatch("setPlatformConfig", res.data);
      }
    );

    const getShowPassword = computed(() => {
      let useChangepassword = store.getters.companyInfo.useChangepassword;
      if (useChangepassword === undefined) return false;
      const flag = ls.get("changePassword") || ls.get("passwordExpire");
      if (useChangepassword === 1 && flag) {
        return true;
      } else {
        store.dispatch("setChangePassword", false);
        store.dispatch("setPasswordExpire", false);
        return false;
      }
    });

    onErrorCaptured((err) => {
      //错误信息上报
      setTimeout(() => {
        let page = location.href;
        report({ error: String(err), router: page }).then((res) => {});
      }, 1000);
    });

    store.dispatch("setIsExternal", ls.get("isExternal") || "");

    return {
      locale,
      getShowPassword,
    };
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  min-height: 100vh;
}
::-webkit-scrollbar-track {
  background: #e5e5e5;
  border-radius: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #c3c3c3;
  transition: color 0.2s ease;
}
</style>
