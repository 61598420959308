import {
  UserOutlined,
  LockOutlined,
  QrcodeOutlined,
  LoginOutlined,
  WechatOutlined,
  DingtalkCircleFilled,
  SearchOutlined,
  RightCircleFilled,
  ClockCircleOutlined,
  FireOutlined,
  PlaySquareOutlined,
  UpOutlined,
  DownOutlined,
  SyncOutlined,
  MessageOutlined,
  LikeOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  EyeOutlined,
  CarryOutOutlined,
  ClockCircleFilled,
  FireFilled,
  StarFilled,
  StarOutlined,
  BuildOutlined,
  BellOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  InfoCircleFilled,
  ProfileOutlined,
  FundProjectionScreenOutlined,
  FolderViewOutlined,
  GroupOutlined,
  CommentOutlined,
  HighlightOutlined,
  PoweroffOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusOutlined,
  LikeFilled,
  FlagFilled,
  FlagOutlined,
  CloseCircleOutlined,
  HeartOutlined,
  HeartFilled,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
  SafetyOutlined,
  RollbackOutlined,
  ApartmentOutlined,
  SafetyCertificateOutlined,
  YoutubeOutlined,
  CaretUpFilled,
  CaretDownFilled,
  DownloadOutlined,
  CheckSquareFilled,
  CloseSquareFilled,
  CheckOutlined,
  CloseOutlined,
  SmallDashOutlined,
  TeamOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  FormOutlined,
  FileUnknownOutlined,
  AuditOutlined,
  UserSwitchOutlined,
  DesktopOutlined,
  ContainerOutlined,
  SendOutlined,
  EditOutlined,
  UploadOutlined,
  SaveOutlined,
  FileProtectOutlined,
  PauseOutlined,
  PaperClipOutlined,
  IdcardOutlined,
  EllipsisOutlined,
  SmileFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  BranchesOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  EnvironmentOutlined,
  AppstoreOutlined,
  FundViewOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  InfoCircleOutlined,
  UsergroupAddOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  BulbOutlined,
  AlertOutlined,
  PoundOutlined,
  EuroCircleOutlined,
  AimOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  WechatFilled,
  LinkOutlined,
  FilePptOutlined,
  PictureOutlined,
  PlayCircleFilled,
  FileDoneOutlined,
  FrownOutlined,
  ExceptionOutlined,
  GlobalOutlined,
  VerticalAlignTopOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretRightOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons-vue';
export default app => {
  app.component(UserOutlined.displayName, UserOutlined);
  app.component(LockOutlined.displayName, LockOutlined);
  app.component(QrcodeOutlined.displayName, QrcodeOutlined);
  app.component(LoginOutlined.displayName, LoginOutlined);
  app.component(WechatOutlined.displayName, WechatOutlined);
  app.component(DingtalkCircleFilled.displayName, DingtalkCircleFilled);
  app.component(SearchOutlined.displayName, SearchOutlined);
  app.component(RightCircleFilled.displayName, RightCircleFilled);
  app.component(ClockCircleOutlined.displayName, ClockCircleOutlined);
  app.component(FireOutlined.displayName, FireOutlined);
  app.component(PlaySquareOutlined.displayName, PlaySquareOutlined);
  app.component(UpOutlined.displayName, UpOutlined);
  app.component(DownOutlined.displayName, DownOutlined);
  app.component(SyncOutlined.displayName, SyncOutlined);
  app.component(MessageOutlined.displayName, MessageOutlined);
  app.component(LikeOutlined.displayName, LikeOutlined);
  app.component(CaretUpOutlined.displayName, CaretUpOutlined);
  app.component(CaretDownOutlined.displayName, CaretDownOutlined);
  app.component(DeleteOutlined.displayName, DeleteOutlined);
  app.component(EyeOutlined.displayName, EyeOutlined);
  app.component(CarryOutOutlined.displayName, CarryOutOutlined);
  app.component(ClockCircleFilled.displayName, ClockCircleFilled);
  app.component(FireFilled.displayName, FireFilled);
  app.component(StarFilled.displayName, StarFilled);
  app.component(StarOutlined.displayName, StarOutlined);
  app.component(BuildOutlined.displayName, BuildOutlined);
  app.component(BellOutlined.displayName, BellOutlined);
  app.component(CalendarOutlined.displayName, CalendarOutlined);
  app.component(CheckCircleOutlined.displayName, CheckCircleOutlined);
  app.component(InfoCircleFilled.displayName, InfoCircleFilled);
  app.component(ProfileOutlined.displayName, ProfileOutlined);
  app.component(FundProjectionScreenOutlined.displayName, FundProjectionScreenOutlined);
  app.component(FolderViewOutlined.displayName, FolderViewOutlined);
  app.component(GroupOutlined.displayName, GroupOutlined);
  app.component(CommentOutlined.displayName, CommentOutlined);
  app.component(HighlightOutlined.displayName, HighlightOutlined);
  app.component(PoweroffOutlined.displayName, PoweroffOutlined);
  app.component(LoadingOutlined.displayName, LoadingOutlined);
  app.component(PlusOutlined.displayName, PlusOutlined);
  app.component(MinusOutlined.displayName, MinusOutlined);
  app.component(LikeFilled.displayName, LikeFilled);
  app.component(FlagFilled.displayName, FlagFilled);
  app.component(FlagOutlined.displayName, FlagOutlined);
  app.component(CloseCircleOutlined.displayName, CloseCircleOutlined);
  app.component(HeartOutlined.displayName, HeartOutlined);
  app.component(HeartFilled.displayName, HeartFilled);
  app.component(LeftOutlined.displayName, LeftOutlined);
  app.component(RightOutlined.displayName, RightOutlined);
  app.component(UnorderedListOutlined.displayName, UnorderedListOutlined);
  app.component(SafetyOutlined.displayName, SafetyOutlined);
  app.component(RollbackOutlined.displayName, RollbackOutlined);
  app.component(ApartmentOutlined.displayName, ApartmentOutlined);
  app.component(SafetyCertificateOutlined.displayName, SafetyCertificateOutlined);
  app.component(YoutubeOutlined.displayName, YoutubeOutlined);
  app.component(CaretUpFilled.displayName, CaretUpFilled);
  app.component(CaretDownFilled.displayName, CaretDownFilled);
  app.component(DownloadOutlined.displayName, DownloadOutlined);
  app.component(CheckSquareFilled.displayName, CheckSquareFilled);
  app.component(CloseSquareFilled.displayName, CloseSquareFilled);
  app.component(CheckOutlined.displayName, CheckOutlined);
  app.component(CloseOutlined.displayName, CloseOutlined);
  app.component(SmallDashOutlined.displayName, SmallDashOutlined);
  app.component(TeamOutlined.displayName, TeamOutlined);
  app.component(SolutionOutlined.displayName, SolutionOutlined);
  app.component(FileSearchOutlined.displayName, FileSearchOutlined);
  app.component(FormOutlined.displayName, FormOutlined);
  app.component(FileUnknownOutlined.displayName, FileUnknownOutlined);
  app.component(AuditOutlined.displayName, AuditOutlined);
  app.component(UserSwitchOutlined.displayName, UserSwitchOutlined);
  app.component(DesktopOutlined.displayName, DesktopOutlined);
  app.component(ContainerOutlined.displayName, ContainerOutlined);
  app.component(SendOutlined.displayName, SendOutlined);
  app.component(EditOutlined.displayName, EditOutlined);
  app.component(UploadOutlined.displayName, UploadOutlined);
  app.component(SaveOutlined.displayName, SaveOutlined);
  app.component(FileProtectOutlined.displayName, FileProtectOutlined);
  app.component(PauseOutlined.displayName, PauseOutlined);
  app.component(PaperClipOutlined.displayName, PaperClipOutlined);
  app.component(IdcardOutlined.displayName, IdcardOutlined);
  app.component(EllipsisOutlined.displayName, EllipsisOutlined);
  app.component(SmileFilled.displayName, SmileFilled);
  app.component(CheckCircleFilled.displayName, CheckCircleFilled);
  app.component(CloseCircleFilled.displayName, CloseCircleFilled);
  app.component(BranchesOutlined.displayName, BranchesOutlined);
  app.component(PlusSquareOutlined.displayName, PlusSquareOutlined);
  app.component(MinusSquareOutlined.displayName, MinusSquareOutlined);
  app.component(EnvironmentOutlined.displayName, EnvironmentOutlined);
  app.component(AppstoreOutlined.displayName, AppstoreOutlined);
  app.component(FundViewOutlined.displayName, FundViewOutlined);
  app.component(FullscreenOutlined.displayName, FullscreenOutlined);
  app.component(FullscreenExitOutlined.displayName, FullscreenExitOutlined);
  app.component(InfoCircleOutlined.displayName, InfoCircleOutlined);
  app.component(UsergroupAddOutlined.displayName, UsergroupAddOutlined);
  app.component(LeftCircleOutlined.displayName, LeftCircleOutlined);
  app.component(RightCircleOutlined.displayName, RightCircleOutlined);
  app.component(BulbOutlined.displayName, BulbOutlined);
  app.component(AlertOutlined.displayName, AlertOutlined);
  app.component(PoundOutlined.displayName, PoundOutlined);
  app.component(EuroCircleOutlined.displayName, EuroCircleOutlined);
  app.component(AimOutlined.displayName, AimOutlined);
  app.component(ExclamationCircleOutlined.displayName, ExclamationCircleOutlined);
  app.component(ExclamationCircleFilled.displayName, ExclamationCircleFilled);
  app.component(QuestionCircleOutlined.displayName, QuestionCircleOutlined);
  app.component(DoubleLeftOutlined.displayName, DoubleLeftOutlined);
  app.component(DoubleRightOutlined.displayName, DoubleRightOutlined);
  app.component(WechatFilled.displayName, WechatFilled);
  app.component(LinkOutlined.displayName, LinkOutlined);
  app.component(FilePptOutlined.displayName, FilePptOutlined);
  app.component(PictureOutlined.displayName, PictureOutlined);
  app.component(PlayCircleFilled.displayName, PlayCircleFilled);
  app.component(FileDoneOutlined.displayName, FileDoneOutlined);
  app.component(FrownOutlined.displayName, FrownOutlined);
  app.component(ExceptionOutlined.displayName, ExceptionOutlined);
  app.component(GlobalOutlined.displayName, GlobalOutlined);
  app.component(VerticalAlignTopOutlined.displayName, VerticalAlignTopOutlined);
  app.component(ArrowLeftOutlined.displayName, ArrowLeftOutlined);
  app.component(ArrowRightOutlined.displayName, ArrowRightOutlined);
  app.component(CaretRightOutlined.displayName, CaretRightOutlined);
  app.component(VerticalAlignBottomOutlined.displayName, VerticalAlignBottomOutlined);
};
