<template>
  <div class="search-box">
    <div class="search-wrap">
      <div class="input">
        <!-- 请输入关键字 -->
        <a-input-search
          v-model:value.trim="state.keyword"
          :placeholder="$t('please_enter_keywords')"
          size="large"
          @search="onSearch"
          maxlength="20"
          allowClear
        >
          <template #enterButton>
            <!-- 搜索 -->
            <a-button type="primary">{{ $t('XB_Search') }}</a-button>
          </template>
        </a-input-search>
      </div>
      <div class="history" v-if="state.historyList.length">
        <div class="title">
          <h5>{{ $t('LB_RecentSearch') }}</h5>
          <div class="clear" @click="clear">
            <DeleteOutlined />
          </div>
        </div>
        <div class="history-wrap">
          <div
            class="item"
            v-for="(item, i) in state.historyList"
            :key="i"
            @click="jump(item)"
          >{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { reactive, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import ls from '@/utils/local-storage';

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const router = useRouter();

const state = reactive({
  keyword: '',
  historyList: ls.get('searchHistory') || [],
})

function onSearch(val) {
  if (!val) return;
  if (!state.historyList.includes(val)) {
    if (state.historyList.length >= 10) {
      state.historyList.splice(0, 1, val);
    } else {
      state.historyList.push(val);
    }
    ls.set('searchHistory', state.historyList);
  }
  jump(val);
};
function clear() {
  state.historyList = [];
  ls.set('searchHistory', []);
};
function jump(val) {
  router.push('/search/' + val);
  state.keyword = '';
  emit('search');
};
const emit = defineEmits(['search']);
</script>
<style lang="less" scoped>
.search-box {
  width: 100%;
  height: 350px;
  border-top: 1px solid #f5f5f5;
  background-color: #fff;
  .search-wrap {
    width: 616px;
    margin: 80px auto 0;
    ::v-deep(.ant-input) {
      height: 33px;
      border-radius: 8px 0 0 8px;
    }
    ::v-deep(.ant-btn) {
      height: 48px;
      padding: 0 28px;
      border-radius: 0 8px 8px 0;
    }
    .history {
      .title {
        .mixinFlex(space-between, center);
        margin: 20px 0;
        h5 {
          margin: 0;
          font-size: 16px;
          color: #333;
        }
        .clear {
          font-size: 20px;
          color: #999;
          cursor: pointer;
          &:hover {
            color: var(--theme);
          }
        }
      }
      &-wrap {
        .mixinFlex();
        flex-wrap: wrap;
        .item {
          line-height: 32px;
          padding: 0 12px;
          margin-left: 12px;
          margin-bottom: 15px;
          background-color: #f4f4f4;
          border-radius: 4px;
          font-size: 16px;
          color: #333;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            color: var(--theme);
          }
        }
      }
    }
  }
}
</style>