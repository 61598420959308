import {
    Button,
    Form,
    Input,
    Carousel,
    Progress,
    Rate,
    message,
    Tooltip,
    Tabs,
    Card,
    List,
    Spin,
    Avatar,
    Empty,
    ConfigProvider,
    Popconfirm,
    Pagination,
    Checkbox,
    Radio,
    Affix,
    Modal,
    BackTop,
    Select,
    DatePicker,
    Upload,
    Anchor,
    Tag,
    InputNumber,
    Result,
    Drawer,
    Statistic,
    Table,
    Grid,
    Row,
    Col,
    Divider,
    Tree,
    Skeleton,
    Image,
    Popover,
    Slider,
    Collapse,
    Timeline,
    TreeSelect,
    Breadcrumb,
    Dropdown,
    Menu,
    Cascader,
    Badge,
    Space,
    Steps,
    Alert,
    Switch,
} from 'ant-design-vue';

message.config({
    top: `60px`,
    duration: 2,
});

export default (app) => {
    app.config.globalProperties.$message = message;
    app.use(Button)
        .use(Form)
        .use(Input)
        .use(Carousel)
        .use(Progress)
        .use(Rate)
        .use(Tooltip)
        .use(Tabs)
        .use(Card)
        .use(List)
        .use(Spin)
        .use(Avatar)
        .use(Empty)
        .use(ConfigProvider)
        .use(Popconfirm)
        .use(Pagination)
        .use(Checkbox)
        .use(Radio)
        .use(Affix)
        .use(Modal)
        .use(BackTop)
        .use(Select)
        .use(DatePicker)
        .use(Upload)
        .use(Anchor)
        .use(Tag)
        .use(InputNumber)
        .use(Result)
        .use(Drawer)
        .use(Statistic)
        .use(Table)
        .use(Grid)
        .use(Row)
        .use(Col)
        .use(Divider)
        .use(Tree)
        .use(Skeleton)
        .use(Image)
        .use(Popover)
        .use(Slider)
        .use(Collapse)
        .use(Timeline)
        .use(TreeSelect)
        .use(Breadcrumb)
        .use(Dropdown)
        .use(Menu)
        .use(Cascader)
        .use(Badge)
        .use(Space)
        .use(Steps)
        .use(Alert)
        .use(Switch)
}
