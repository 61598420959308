<template>
  <!-- 外部账号不显示 -->
  <template v-if="!isExternal">
    <Head v-if="ISHTSY" />
    <NewHead v-else />
  </template>
  <section class="app-main">
    <template v-if="key.includes('/mine')">
      <router-view />
    </template>
    <template v-else>
      <router-view :key="key" />
    </template>
    <a-back-top>
      <div class="icon">
        <VerticalAlignTopOutlined />
      </div>
    </a-back-top>
    <!-- 答疑助手 -->
    <qa-helper v-if="isQa" />
  </section>
  <Foot />
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Head from "@/components/layout/Head.vue";
import NewHead from "@/components/layout/NewHead.vue";
import Foot from "@/components/layout/Foot.vue";
import { useStore } from "vuex";
import qaHelper from "@/components/qaHelper.vue";
export default {
  name: "AppMain",
  components: {
    Head,
    NewHead,
    Foot,
    qaHelper,
  },
  setup() {
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const route = useRoute();
    const store = useStore();

    let key = computed(() => route.path);
    let isExternal = computed(() => store.getters.isExternal);
    let isQa = computed(() => {
      const data = store.getters.companyInfo;
      return data.menu && data.menu.includes('qa') && data.useQa === 1
    });

    return {
      ISHTSY,
      key,
      isExternal,
      isQa,
    };
  },
};
</script>

<style lang="less" scoped>
.app-main {
  background-color: @color-page-light;
  min-height: calc(100vh - 175px);
}
::v-deep(.ant-back-top) {
  width: 58px;
  height: 58px;
  line-height: 58px;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  right: 40px;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 rgba(148, 148, 148, 0.15);
  .icon {
    font-size: 30px;
    color: #333;
  }
  &:hover {
    .icon {
      color: var(--theme);
    }
  }
}
</style>
