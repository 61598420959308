<template>
  <a-modal
    v-model:visible="visible"
    title=""
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    :footer="null"
    :width="companyInfo.useStrongPass == 1 ? '1160px' : '580px'"
    :bodyStyle="{ padding: 0 }"
  >
    <div class="password-wrap">
      <div class="password-rule" v-if="companyInfo.useStrongPass == 1">
        <h5>{{ $t("login.password_rules") }}</h5>
        <div class="day" v-if="companyInfo.passExpire > 0">
          {{
            $t("login.strong_password_rules_0", {
              number: companyInfo.passExpire,
            })
          }}
        </div>
        <p>
          <CaretRightOutlined class="icon" />{{
            $t("login.strong_password_rules_1")
          }}
        </p>
        <p>
          <CaretRightOutlined class="icon" />{{
            $t("login.strong_password_rules_2")
          }}
        </p>
        <p>
          <CaretRightOutlined class="icon" />{{
            $t("login.strong_password_rules_3")
          }}
        </p>
        <p>
          <CaretRightOutlined class="icon" />{{
            $t("login.strong_password_rules_4")
          }}
        </p>
        <p>
          <CaretRightOutlined class="icon" />{{
            $t("login.strong_password_rules_5")
          }}
        </p>
      </div>
      <div
        class="password-form"
        :style="{ width: companyInfo.useStrongPass == 1 ? '50%' : '100%' }"
      >
        <h5>{{ $t("CM_ModifyPassword") }}</h5>
        <a-form
          ref="formRef"
          layout="vertical"
          :model="formState"
          :rules="rules"
          @finish="handleFinish"
        >
          <div class="tips">
            {{ $t("login.to_improve_account_security") }}{{ changePasswordTip
            }}{{ $t("login.original_password_must_be_changed") }}
          </div>
          <a-form-item
            :label="$t('login.new_login_password')"
            has-feedback
            name="newpass"
          >
            <a-input-password
              v-model:value="formState.newpass"
              type="password"
              size="large"
              autocomplete="off"
              :placeholder="$t('login.new_login_password')"
            />
          </a-form-item>
          <a-form-item
            :label="$t('login.confirm_new_password')"
            has-feedback
            name="newpass2"
          >
            <a-input-password
              v-model:value="formState.newpass2"
              type="password"
              size="large"
              autocomplete="off"
              :placeholder="$t('login.confirm_new_password')"
            />
          </a-form-item>
          <div class="btns">
            <a-button
              type="primary"
              :loading="confirmLoading"
              html-type="submit"
              >{{ $t("CM_OK") }}</a-button
            >
          </div>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, getCurrentInstance, ref, watch, computed } from "vue";
import checkPassword from "@/utils/checkPassword";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import { pwdEdit } from "@/api/user";
export default {
  name: "changePassword",
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const confirmLoading = ref(false);
    const companyInfo = computed(() => store.getters.companyInfo);
    const formRef = ref();
    const formState = reactive({
      oldpass: "",
      newpass: "",
      newpass2: "",
    });
    const changePasswordTip = ref("");

    let visible = ref(false);
    watch(
      () => route.path,
      () => {
        if (route.meta.requireAuth) {
          visible.value = ls.get("changePassword") || ls.get("passwordExpire");
          if (ls.get("changePassword"))
            changePasswordTip.value = proxy.$t("login.first_login_to_account");
          if (ls.get("passwordExpire"))
            changePasswordTip.value = proxy.$t("login.login_password_expired");
        }
      },
      { immediate: true, deep: true }
    );

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject(
          proxy.$t("login.please_enter_confirmation_password")
        );
      } else if (value !== formState.newpass) {
        return Promise.reject(proxy.$t("login.two_passwords_are_inconsistent"));
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      newpass: [
        {
          required: true,
          message: proxy.$t("login.please_enter_new_password"),
          trigger: "change",
        },
        {
          validator: checkPassword,
          trigger: "change",
        },
      ],
      newpass2: [
        {
          required: true,
          validator: validatePass2,
          trigger: "change",
        },
      ],
    };

    const handleFinish = (values) => {
      confirmLoading.value = true;
      pwdEdit({
        newpass: proxy.$getRsaCode(formState.newpass),
      })
        .then((res) => {
          if (res.ret === 0) {
            visible.value = false;
            store.dispatch("setChangePassword", false);
            store.dispatch("setPasswordExpire", false);
            proxy.$message.success(proxy.$t("mine.successfully_modified"));
          }
        })
        .finally((res) => {
          confirmLoading.value = false;
        });
    };
    return {
      visible,
      companyInfo,
      changePasswordTip,
      confirmLoading,
      formState,
      formRef,
      rules,
      handleFinish,
    };
  },
};
</script>

<style lang="less" scoped>
.password-wrap {
  .mixinFlex(space-between);
  padding: 24px;
  .password-rule {
    width: calc(50% + 24px);
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #f1f1f1;
    font-size: 14px;
    line-height: 1.6;
    h5 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 12px;
      padding-bottom: 12px;
    }
    .day {
      color: red;
      margin-bottom: 12px;
    }
    p {
      color: #555;
      margin-bottom: 12px;
      .mixinFlex(flex-start);
      .icon {
        color: @color-theme;
        font-size: 12px;
        margin-right: 4px;
        padding-top: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .password-form {
    width: 50%;
    h5 {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.6;
      text-align: center;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #f1f1f1;
    }
    .tips {
      color: #666;
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 1.6;
    }
    ::v-deep(.ant-form) {
      padding: 0 24px;
      .ant-form-item-label > label {
        color: #666;
      }
    }
    .ant-form-item {
      margin-bottom: 26px;
    }
    .btns {
      text-align: center;
      .ant-btn {
        margin: 24px auto 0;
        padding: 14px 84px;
        height: 44px;
        line-height: 14px;
      }
    }
  }
}
</style>