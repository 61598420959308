<template>
  <div class="qa-helper" @click="visible = true">
    <div class="icon"></div>
    <span>{{ $t('Consult') }}</span>
  </div>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    :footer="null"
    width="60%"
    :title="$t('AdviceOnIssues')"
  >
    <div class="qa-bg"></div>
    <div class="qa" id="qa-answer">
      <div class="qa-wrap sticky">
        <!-- 分类置顶 -->
        <div class="qa-item a">
          <div class="avatar">
            <div class="icon"></div>
          </div>
          <div class="content">
            <div class="tip">{{ $t('QAHelperCatalogTip') }}</div>
            <div class="catalog">
              <div
                class="catalog-item"
                v-for="item in catalog.list"
                :key="item.id"
                :title="item.name"
                @click="queryQuestionById(item)"
              >
                {{ item.name }}
              </div>
              <div class="no-catalog" v-if="!catalog.list.length">{{ $t('Pub_Lab_NoData') }}</div>
            </div>
            <div class="page" v-if="catalog.total > 6">
              <a-button
                class="pre"
                type="text"
                size="small"
                :disabled="catalog.page === 1"
                @click="preCatalog"
              >{{ $t("cm_pre") }}</a-button>
              <a-button
                class="next"
                type="text"
                size="small"
                :disabled="catalog.page === catalog.max"
                @click="nextCatalog"
              >{{ $t("cm_next") }}</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="qa-wrap" v-for="(item, index) in state.list" :key="index">
        <div class="qa-item a" :id="item.id" v-if="item.type === 'a'">
          <div class="avatar">
            <div class="icon"></div>
          </div>
          <div class="content">
            <!-- 分类问题 -->
            <div v-if="item.list" class="question">
              <div class="tip">{{ $t('QAHelperAnswerTip', { type: item.name }) }}</div>
              <ul>
                <li
                  class="question-item"
                  v-for="(col, colIndex) in item.list"
                  :key="col.qaId"
                  :title="col.question"
                  @click="clickQuestion(col)"
                 >
                   {{ colIndex + 1 }}、{{ col.question }}
                 </li>
              </ul>
              <div class="page" v-if="item.total > 5">
                <a-button
                  class="pre"
                  type="text"
                  size="small"
                  :disabled="item.page === 1"
                  @click="preQuestion(item)"
                >{{ $t("cm_pre") }}</a-button>
                <a-button
                  class="next"
                  type="text"
                  size="small"
                  :disabled="item.page === item.max"
                  @click="nextQuestion(item)"
                >{{ $t("cm_next") }}</a-button>
              </div>
            </div>
            <div class="wangeditor-box" v-else v-html="item.content"></div>
          </div>
        </div>
        <div class="qa-item q" v-else>
          <div class="avatar">
            <a-avatar :src="userInfo.portrait" :size="40"></a-avatar>
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="input">
      <a-input
        v-model:value="keyword"
        size="large"
        :placeholder="$t('PleaseDescribeYourProblem')"
        @pressEnter="queryQuestion"
      >
        <template #suffix>
          <span class="enter" @click="queryQuestion">
          </span>
        </template>
      </a-input>
    </div>
  </a-modal>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { ref, reactive, getCurrentInstance, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { getQaTree, getQaList } from '@/api/user';

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const store = useStore();
const userInfo = computed(() => store.getters.userInfo);
const visible = ref(false);
const keyword = ref('');

const treeList = ref([]);
const catalog = reactive({
  list: [],
  page: 1,
  pageSize: 6,
  total: 0,
  max: 1,
});
function getData() {
  getQaTree().then(res => {
    if (res.ret === 0 && res.data) {
      treeList.value = res.data || [];
      catalog.total = treeList.value.length;
      catalog.max = Math.ceil(catalog.total / catalog.pageSize);
      sliceCatalogList();
    }
  })
};
function sliceCatalogList() {
  const s = (catalog.page - 1) * 6;
  const e = s + 6;
  catalog.list = treeList.value.slice(s, e);
}
getData();
function preCatalog() {
  catalog.page -= 1;
  sliceCatalogList();
};
function nextCatalog() {
  catalog.page += 1;
  sliceCatalogList();
};

const state = reactive({
  list: [],
});
async function queryQuestion() {
  if (!keyword.value.trim()) return;
  const domId = 'anchor-' + new Date().getTime();
  state.list.push({
    content: keyword.value,
    type: 'q',
    
  })
  const res = await getQaList({ qaKeyword: keyword.value });
  if (res.ret === 0 && res.data.list) {
    state.list.push({
      content: res.data.list[0].answers,
      type: 'a',
      id: domId,
    })
  } else {
    state.list.push({
      content: $t('QAHelperAnswerTip2'),
      type: 'a',
      id: domId,
    })
  }
  keyword.value = '';
  setScrollTo(domId);
};
async function queryQuestionById(item) {
  const domId = 'anchor-' + new Date().getTime();
  state.list.push({
    content: item.name,
    type: 'q',
  })
  const p = {
    page: 1,
    pageSize: 5,
    folderId: item.id,
    max: 0,
    total: 0,
  }
  const res = await getQaList(p);
  if (res.ret === 0 && res.data.list) {
    p.total = res.data.totals;
    p.max = Math.ceil(res.data.totals / p.pageSize);
    state.list.push({
      list: res.data.list,
      type: 'a',
      name: item.name,
      ...p,
      id: domId,
    })
  } else {
    state.list.push({
      content: $t('QAHelperAnswerTip3'),
      type: 'a',
      id: domId,
    })
  }
  setScrollTo(domId);
};
async function getList(item) {
  const p = {
    page: item.page,
    pageSize: item.pageSize,
    folderId: item.folderId,
  }
  const res = await getQaList(p);
  if (res.ret === 0) {
    item.total = res.data.totals;
    item.max = Math.ceil(res.data.totals / item.pageSize);
    item.list = res.data.list;
  }
};
function preQuestion(item) {
  item.page -= 1;
  getList(item);
};
function nextQuestion(item) {
  item.page += 1;
  getList(item);
};
function clickQuestion(item) {
  const domId = 'anchor-' + new Date().getTime();
  state.list.push({
    content: item.question,
    type: 'q',
  })
  state.list.push({
    content: item.answers,
    type: 'a',
    id: domId,
  })
  setScrollTo(domId);
};
function setScrollTo(id) {
  let container = document.querySelector("#qa-answer");
  nextTick(() => {
    let to = document.querySelector(`#${id}`);
    if (!to || !container) return;
    const toTop = to.getBoundingClientRect().top;
    const top = toTop + container.scrollTop;
    container.scrollTop = top;
  });
};

</script>
<style lang="less" scoped>
.qa-helper {
  position: fixed;
  top: 70%;
  right: 70px;
  width: 58px;
  padding: 5px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 rgba(148, 148, 148, 0.15);
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  .icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: url('~@/assets/image/qa-helper.png') no-repeat;
    background-size: cover;
  }
}
.qa-bg {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #DBF4FF;
}
.qa {
  position: relative;
  height: 70vh;
  padding-bottom: 10px;
  overflow-y: auto;
  animation: all 100ms ease-in-out;
  &-wrap {
    margin-bottom: 10px;
    .qa-item {
      display: flex;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // background-color: #fff;
      }
      .content {
        position: relative;
        padding: 10px;
        border-radius: 4px;
        max-width: calc(100% - 100px);
        word-break: break-all;
        white-space: pre-wrap;
        color: #333;
      }
    }
    .q {
      flex-direction: row-reverse;
      .content {
        margin-right: 16px;
        background-color: #2a92c4;
        color: #fff;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 10px solid #2a92c4;
          position: absolute;
          top: 15px;
          right: -10px;
        }
      }
    }
    .a {
      .avatar {
        padding: 3px;
        border: 1px solid #2c2c2c;
        .icon {
          width: 100%;
          height: 100%;
          background: url('~@/assets/image/qa-helper.png') no-repeat;
          background-size: 100%;
        }
      }
      .content {
        margin-left: 16px;
        background-color: #f1f7ff;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 10px solid #f1f7ff;
          position: absolute;
          top: 15px;
          left: -10px;
        }
      }
    }
  }
  .sticky {
    position: sticky;
    top: 0;
    z-index: 11;
    margin-bottom: 0;
    padding-bottom: 15px;
    background-color: #DBF4FF;
    .catalog {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      margin-top: 15px;
      &-item {
        width: 45%;
        padding: 0 10px;
        margin-bottom: 15px;
        line-height: 32px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        background-color: #fff;
        color: var(--theme);
        cursor: pointer;
        &:nth-child(2n + 1) {
          margin-right: 5%;
        }
      }
      .no-catalog {
        margin: 15px auto;
        text-align: center;
        color: #999;
      }
    }
  }
  .page {
    text-align: center;
    display: flex;
    justify-content: space-around;
    .pre, .next {
      border-width: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .question {
    min-width: 400px;
    &-item {
      margin: 10px 0;
      color: var(--theme);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
.input {
  padding-top: 10px;
  // background-color: #DBF4FF;
  .enter {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('~@/assets/image/send-input.png') no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}
</style>
